$grid-breakpoints: (
    // Extra small screen / phone
        xs: 0,
    // Small screen / phone
        sm: 576px,
    // Medium screen / tablet
        md: 768px,
    // Large screen / desktop
        lg: 992px,
    // Extra large screen / wide desktop
        xl: 1200px,
    // Slackpot
        xxl: 1400px
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1320px
);
