@import "variables";
@import "grid";

@import "../node_modules/bootstrap/scss/bootstrap";
@import "typography";

.navbar-toggler-icon {
    background-image: url('/images/burger.svg');
}

.btn {
    min-width: 240px;
}

header {
    background-color: rgba(169,133,255,1);

    > .container {
        a {
            font-size: 17px;
            font-weight: 500;
        }
    }
}

footer {
    background-color: #B898FF;
    color: white;
    font-size: 14px;

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    a {
        color: white;
    }

    .stores {
        text-align: right;

        @include media-breakpoint-down(sm) {
            text-align: center;
        }
    }
}

.summin-stage {
    @include media-breakpoint-down(lg) {
        text-align: center;
    }

    background: rgb(147,202,255);
    background: linear-gradient(0deg, rgba(153,178,255,1) 0%, rgba(169,133,255,1) 100%);

    color: white;

    h1 {
        font-size: 80px;

        @include media-breakpoint-down(md) {
            font-size: 45px;
        }
    }

    p {
        font-weight: 200;
        font-size: 25px;
    }

    .get-in-touch {
        font-weight: 700;
        font-size: 25px;

        @include media-breakpoint-down(md) {
            font-size: 18px;
        }
    }

    .d-xl-none {
        img {
            max-width: 100%;
        }
    }

    .screens {
        .d-xl-block > img {
            position: absolute;
        }

        img {
            &:first-child {
                top: -50px;
                z-index: 2;
            }

            &:last-child {
                left: 200px;
                z-index: 1;
            }
        }
    }
}

.summin-facts, .summin-external, .summin-demo {
    background: rgb(236,247,255);
    background: linear-gradient(0deg, rgba(235,247,255,1) 0%, rgba(254,254,254,1) 100%);

    &.summin-external {
        background: linear-gradient(0deg, #EEECFF 0%, #FEFEFE 100%);
    }

    &.summin-demo {
        h2 {
            font-size: 40px;
        }
    }

    .card {
        border: none;
        border-radius: 15px;

        @include media-breakpoint-down(lg) {
            margin: 20px 0;
            max-width: 50%;
        }

        @include media-breakpoint-down(md) {
            max-width: 75%;
        }

        @include media-breakpoint-down(sm) {
            max-width: inherit;
        }

        .card-title {
            color: #A690FF;
            font-weight: 500;
            font-size: 28px;
            text-align: center;
        }

        .card-body {
            > .image {
                text-align: center;
                padding: 20px 0;

                img {
                    height: 120px;
                }
            }
        }
    }
}

.summin-business {
    background: linear-gradient(0deg, #EEECFF 0%, #FEFEFE 100%);

    img {
        max-width: 100%;
    }

    ul {
        list-style-image: url('/images/plus.svg');

        li {
            font-size: 22px;
            font-weight: 400;
            padding-left: 20px;
        }
    }
}

.summin-contact {
    h2 {
        color: $s-dark-grey;
        font-size: 39px;
        font-weight: 500;
    }

    form {
        @include media-breakpoint-up(lg) {
            max-width: 50%;
        }

        input, textarea {
            font-size: 30px;
            padding: 0 25px;
            border-radius: 40px;
            border: 0;
            background-color: #E7E0FF;
        }

        textarea {
            padding: 25px 25px;
        }

        .btn-primary {
            margin: 20px;
        }

        small {
            font-size: 10px;

            a, a:hover, a:active, a:focus, a:visited {
                color: black !important;
                text-decoration: underline;
            }
        }
    }
}

.grecaptcha-badge {
    display: none !important;
}

.privacy, .imprint {
    a, a:hover, a:focus, a:visited, a:active {
        color: black !important;
        text-decoration: underline;
    }
}

.pwa-button {
    height: 45px;
}
