/* roboto-300 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: local(''),
        url('./fonts/roboto-v30-latin-300.woff2') format('woff2'),
        url('./fonts/roboto-v30-latin-300.woff') format('woff');
}

/* roboto-regular - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url('./fonts/roboto-v30-latin-regular.woff2') format('woff2'),
        url('./fonts/roboto-v30-latin-regular.woff') format('woff');
}

/* roboto-500 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: local(''),
        url('./fonts/roboto-v30-latin-500.woff2') format('woff2'),
        url('./fonts/roboto-v30-latin-500.woff') format('woff');
}

/* roboto-700 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: local(''),
        url('./fonts/roboto-v30-latin-700.woff2') format('woff2'),
        url('./fonts/roboto-v30-latin-700.woff') format('woff');
}

/* roboto-900 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src: local(''),
        url('./fonts/roboto-v30-latin-900.woff2') format('woff2'),
        url('./fonts/roboto-v30-latin-900.woff') format('woff');
}

body {
    font-family: 'Roboto', sans-serif;
    color: $s-dark-grey;
    font-size: 20px;
    font-weight: 300;
}

h1 {
    font-weight: 700;
    font-size: 40px;

    @include media-breakpoint-down(md) {
        font-size: 25px;
    }
}

h2 {
    font-size: 51px;
    color: #A0A2FF;

    @include media-breakpoint-down(md) {
        font-size: 40px;
    }
}

button.btn-lg {
    font-size: 19px;
    height: 58px;
}

a, a.text-light {
    color: white;
    text-decoration: none;

    &:active, &:hover, &:visited, &:focus {
        color: white !important;
        text-decoration: underline;
    }
}
